import React from 'react';
import Cookies from 'universal-cookie';
import PropTypes from 'prop-types';

class VenueName extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: props.venue.name
    };
    this.saveUpdate = this.saveUpdate.bind(this);
    this.updateName = this.updateName.bind(this);
  }

  updateName(event) {
    this.setState({ name: event.target.value });
  }

  saveUpdate() {
    const { venue, updateVenue } = this.props;
    const { name } = this.state;
    const cookies = new Cookies();
    const token = cookies.get("X-CSRF-Token");
    const data = { venue: { name } };

    fetch(`/venues/${venue.id}`, {
      method: 'put',
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        'X-CSRF-Token': token
      },
    }).then(response => response.json())
      .then((json) => {
        if (json.error == null) {
          updateVenue(json.venue);
        } else {
          alert(json.error);
          console.log(json);
        }
      });
  }

  render() {
    const { name } = this.state;
    return (
      <div className="venue-name-container">
        <b>Venue Name</b><br />
        <input type="text" className="venue-input venue-name" value={name} onChange={this.updateName} />
        <button type="button" className="btn btn-round btn-primary update-venue" onClick={this.saveUpdate}>Update Name</button>
      </div>
    );
  }
}

VenueName.propTypes = {
  updateVenue: PropTypes.func.isRequired,
  venue: PropTypes.object.isRequired
};

export default VenueName;
