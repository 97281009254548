import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import Cookies from 'universal-cookie';
import VenueList from './VenueList';
import VenueName from './VenueName';
import VenueRooms from './VenueRooms';
import { confirmAlert } from "react-confirm-alert";

// Duplicated this CSS in app's CSS sheets, because it was
// not loading in production. TODO: figure out why.
// import "react-confirm-alert/src/react-confirm-alert.css";

const modalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)'
  }
};

Modal.setAppElement('#container');

class Venue extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      addRoomModalOpen: false,
      editRoomModalOpen: false,
      rooms: props.rooms,
      venue: props.venue,
      venues: props.venues
    };
    this.updateRooms = this.updateRooms.bind(this);
    this.updateVenue = this.updateVenue.bind(this);
    this.openAddModal = this.openAddModal.bind(this);
    this.closeAddModal = this.closeAddModal.bind(this);
    this.openEditModal = this.openEditModal.bind(this);
    this.closeEditModal = this.closeEditModal.bind(this);
    this.deleteVenueConfirm = this.deleteVenueConfirm.bind(this);
  }

  openAddModal() {
    this.setState({ addRoomModalOpen: true });
  }

  closeAddModal() {
    this.setState({ addRoomModalOpen: false });
  }

  openEditModal() {
    this.setState({ editRoomModalOpen: true });
  }

  closeEditModal() {
    this.setState({ editRoomModalOpen: false });
  }

  updateVenue(venue) {
    const { venues } = this.props;
    const newVenues = venues.filter(ele => ele.id !== venue.id).concat(venue);
    this.setState({
      venues: newVenues,
      venue
    });
  }

  updateRooms(room) {
    const { rooms } = this.state;
    const newRooms = rooms.filter(ele => ele.id !== room.id).concat(room);
    this.setState({ rooms: newRooms });
  }

  deleteVenueConfirm() {
    confirmAlert({
      title: "Confirm removal",
      message: "Are you sure you want to delete this venue? This is irreversible. You accept responsibility if you delete the wrong thing.",
      buttons: [
        {
          label: "Yes, delete",
          onClick: () => this.deleteVenue()
        },
        {
          label: "No, cancel",
          onClick: () => {}
        }
      ]
    });
  }

  deleteVenue() {
    const { venue } = this.props;
    const cookies = new Cookies();
    const token = cookies.get("X-CSRF-Token");

    fetch(`/venues/${venue.id}}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": token
      }
    })
      .then(response => response.json())
      .then(json => {
        if (json.error == null) {
          alert("Venue deleted. Reloading...");
          window.location.replace("/venues");
        } else {
          alert(json.error);
          console.log(json);
        }
      });
  }

  render() {
    const { rooms, addRoomModalOpen, editRoomModalOpen, venue, venues } = this.state;
    return (
      <div className="venue-container">
        <h2>Venue Management</h2>
        <div className="row">
          <div className="col-md-3">
            <VenueList venues={venues} />
          </div>
          <div className="col-md-9">
            <VenueName venue={venue} updateVenue={this.updateVenue} />
            <VenueRooms
              addRoomModalOpen={addRoomModalOpen}
              closeAddModal={this.closeAddModal}
              closeEditModal={this.closeEditModal}
              editRoomModalOpen={editRoomModalOpen}
              openEditModal={this.openEditModal}
              rooms={rooms}
              updateRooms={this.updateRooms}
              venue={venue}
            />
            <button type="button" className="btn btn-round btn-primary add-room-button" onClick={this.openAddModal}>+ Add a Room</button>
            <div style={{ paddingTop: "40px"}}>
              <button type="button" className="btn btn-round btn-danger" onClick={this.deleteVenueConfirm}>Delete Venue</button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Venue.propTypes = {
  rooms: PropTypes.array.isRequired,
  venue: PropTypes.object.isRequired,
  venues: PropTypes.array.isRequired
};

export default Venue;
