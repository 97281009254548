import React from 'react';
import PropTypes from 'prop-types';
import sortBy from "lodash/sortBy";

class MeetingGroupTimeList extends React.Component {
  renderTimes() {
    const { times } = this.props;
    return sortBy(times, ['meeting_date', 'meeting_time_start']).map(t => this.renderTime(t));
  }

  renderTime(t) {
    const { time } = this.props;
    if (time.id === t.id) {
      return (
        <tr key={t.id}>
          <td className="venue-list">
            <div className="select-flag padding">
              <b>{t.name}</b>
            </div>
          </td>
        </tr>
      );
    }
    return (
      <tr key={t.id}>
        <td className="venue-list">
          <a href={`/times/${t.id}`}>
            <div className="select-flag padding">
              {t.name}
            </div>
          </a>
        </td>
      </tr>
    );
  }

  render() {
    return (
      <table className="table table-bordered table-striped settings venue-list-table">
        <tbody>
          {this.renderTimes()}
          <tr>
            <td className="venue-list">
              <a href="/times/new">
                <div className="select-flag padding">
                  + Create New 1:1 Meeting Time
                </div>
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    );
  }
}

MeetingGroupTimeList.propTypes = {
  times: PropTypes.array.isRequired,
  time: PropTypes.object.isRequired
};

export default MeetingGroupTimeList;
