import React from "react";
import PropTypes from "prop-types";
import Modal from "react-modal";
import Select from "react-select";
import Cookies from "universal-cookie";
import sortBy from "lodash/sortBy";

const modalStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    overflow: "visible"
  }
};

Modal.setAppElement("#container");

class MeetingGroupAddEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      host: { id: null, full_name: "(unassigned)" },
      attendees: [],
      topic: "",
      conflicts: null
    };
    this.addAttendee = this.addAttendee.bind(this);
    this.clearForm = this.clearForm.bind(this);
    this.removeAttendee = this.removeAttendee.bind(this);
    this.removeHost = this.removeHost.bind(this);
    this.saveAdd = this.saveAdd.bind(this);
    this.saveEdit = this.saveEdit.bind(this);
    this.updateHost = this.updateHost.bind(this);
  }

  setConflicts(conflicts) {
    this.setState({ conflicts });
  }

  clearForm() {
    this.setState({
      host: { id: null, full_name: "(unassigned)" },
      attendees: [],
      conflicts: null
    });
  }

  updateHost(option) {
    const { attendees } = this.props;
    const id = option.value;
    if (id === 0) {
      return;
    }
    const host = attendees.find(att => att.id === id);
    this.setState({ host });
  }

  addAttendee(option) {
    const { attendees } = this.props;
    const id = option.value;
    if (id === 0) {
      return;
    }
    const attendee = attendees.find(att => att.id === id);
    this.setState(prevState => ({
      attendees: prevState.attendees.concat(attendee)
    }));
  }

  removeAttendee(id) {
    const { attendees } = this.props;
    const attendee = attendees.find(att => att.id === id);
    this.setState(prevState => ({
      attendees: prevState.attendees.filter(ele => ele.id !== attendee.id)
    }));
  }

  removeHost() {
    this.setState({ host: { id: null, full_name: "(unassigned)" } });
  }

  /* eslint-disable camelcase */
  saveAdd() {
    const { updateGroups, closeModal, time } = this.props;
    const { host, attendees } = this.state;
    const cookies = new Cookies();
    const token = cookies.get("X-CSRF-Token");
    const data = {
      host_id: host.id,
      attendee_ids: attendees.map(att => att.id)
    };

    fetch(`/times/${time.id}/execs`, {
      method: "post",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": token
      }
    })
      .then(response => response.json())
      .then(json => {
        if (json.error == null) {
          updateGroups(json.group);
          closeModal();
          this.clearForm();
        } else if (json.conflicts) {
          this.setConflicts(json.conflicts);
          console.log(json);
        } else {
          alert(json.error);
          console.log(json);
        }
      });
  }

  saveEdit() {
    const {
      updateGroups,
      closeModal,
      selectedMeetingForEdit,
      resetMeetingForEdit,
      time
    } = this.props;
    const { host, attendees, topic } = this.state;
    const cookies = new Cookies();
    const token = cookies.get("X-CSRF-Token");
    const data = {
      host_id: host.id,
      attendee_ids: attendees.map(att => att.id),
      topic: topic
    };

    fetch(`/times/${time.id}/execs/${selectedMeetingForEdit.id}`, {
      method: "put",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": token
      }
    })
      .then(response => response.json())
      .then(json => {
        if (json.error == null) {
          updateGroups(json.group);
          closeModal();
          resetMeetingForEdit();
        } else if (json.conflicts) {
          this.setConflicts(json.conflicts);
          console.log(json);
        } else {
          alert(json.error);
          console.log(json);
        }
      });
  }

  excludedAttendees() {
    // id values of attendees selected as host or attendee already
    const { host, attendees } = this.state;
    let excluded = [];
    if (host) {
      excluded.concat(host.id);
    }
    excluded = excluded.concat(attendees.map(att => att.id));
    return excluded;
  }

  attendeeSelectOptions() {
    const { attendees } = this.props;
    const excludedIds = this.excludedAttendees();
    const attendeeList = sortBy(
      attendees.filter(att => !excludedIds.includes(att.id)),
      ["first_name", "last_name"]
    );
    return attendeeList.map(att => ({
      value: att.id,
      label: `${att.full_name}, ${att.company}`
    }));
  }

  renderAttendeeName(attendee) {
    if (attendee) {
      return attendee.full_name;
    }
    return "(unassigned)";
  }

  renderAttendeeCompany(attendee) {
    if (attendee.id !== null) {
      return attendee.company;
    }
    return "";
  }

  renderAttendeeTitle(attendee) {
    if (attendee.id !== null) {
      return attendee.job_title;
    }
    return "";
  }

  renderAttendeeTable(attendees) {
    const attendeeList = [].concat(attendees);
    return attendeeList.map(att => (
      <tr key={`attendee-${att.id}`}>
        <td className="group-modal-attendee-table-name">
          {this.renderAttendeeName(att)}
        </td>
        <td className="group-modal-attendee-table-company">
          {this.renderAttendeeCompany(att)}
        </td>
        <td className="group-modal-attendee-table-title">
          {this.renderAttendeeTitle(att)}
        </td>
        <td className="group-modal-attendee-table-actions">
          <a
            onClick={() => {
              this.removeAttendee(att.id);
            }}
          >
            remove
          </a>
        </td>
      </tr>
    ));
  }

  renderHost(host) {
    if (host.id === null) {
      return (
        <tr>
          <td className="group-modal-attendee-table-name">
            (no host assigned)
          </td>
          <td className="group-modal-attendee-table-company">&nbsp;</td>
          <td className="group-modal-attendee-table-title">&nbsp;</td>
          <td className="group-modal-attendee-table-actions">&nbsp;</td>
        </tr>
      );
    }
    return (
      <tr key={`host-${host.id}`}>
        <td className="group-modal-attendee-table-name">
          {this.renderAttendeeName(host)}
        </td>
        <td className="group-modal-attendee-table-company">
          {this.renderAttendeeCompany(host)}
        </td>
        <td className="group-modal-attendee-table-title">
          {this.renderAttendeeTitle(host)}
        </td>
        <td className="group-modal-attendee-table-actions">
          <a
            onClick={() => {
              this.removeHost();
            }}
          >
            remove
          </a>
        </td>
      </tr>
    );
  }

  renderConflicts() {
    const { conflicts } = this.state;
    if (conflicts) {
      return (
        <div className="row">
          <div className="col-md-12">
            <div className="meeting-group-modal-conflicts">
              {conflicts.map(conflict => this.renderConflict(conflict))}
            </div>
          </div>
        </div>
      );
    }
    return <React.Fragment />;
  }

  renderConflict(conflict) {
    let message = "";
    if (conflict.existing.meeting_group_time_id) {
      message = `{conflict.attendee.first_name} {conflict.attendee.last_name} is scheduled for another meeting group at this time.`;
    } else {
      message = `{conflict.attendee.first_name} {conflict.attendee.last_name} is scheduled for a session at this time.`;
    }

    return <p key={`conflict-attendee-${conflict.attendee.id}`}>{message}</p>;
  }

  render() {
    const { modalOpen, closeModal, time } = this.props;
    const { host, attendees } = this.state;
    return (
      <Modal
        isOpen={modalOpen}
        onRequestClose={closeModal}
        style={modalStyles}
        contentLabel="Meeting Group"
      >
        <div className="meeting-group-add-modal">
          <h2>Meeting Group</h2>
          <div className="row">
            <div className="col-md-6">
              <b>Date:</b> {time.meeting_date}
            </div>
            <div className="col-md-6">
              <b>Time:</b> {time.meeting_time_start_fmt}
            </div>
          </div>
          <div className="row">

          </div>

          <div className="row">
            <div className="col-md-11">
              <label>Host</label>
              <br />
              <table className="attendee-table">
                <tbody>{this.renderHost(host)}</tbody>
              </table>
              <label>Set Host:</label>
              <br />
              <Select
                value={0}
                onChange={this.updateHost}
                options={this.attendeeSelectOptions()}
                onSelectResetsInput
                placeholder="Select a host"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-11">
              <label>Attendees</label>
              <br />
              <table className="attendee-table">
                <tbody>{this.renderAttendeeTable(attendees)}</tbody>
              </table>
              <label>Add Attendee:</label>
              <br />
              <Select
                value={0}
                onChange={this.addAttendee}
                options={this.attendeeSelectOptions()}
                onSelectResetsInput
                placeholder="Select attendee to add"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="pull-right">
                <button
                  className="btn btn-info btn-round"
                  type="button"
                  onClick={closeAddModal}
                >
                  Cancel
                </button>
                <button
                  className="btn btn-primary btn-round"
                  type="button"
                  onClick={this.saveChanges}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}
/* eslint-enable camelcase */

MeetingGroupAddEdit.defaultProps = {
  resetMeetingForEdit() {},
  selectedMeetingForEdit: {}
};

MeetingGroupAddEdit.propTypes = {
  attendees: PropTypes.array.isRequired,
  closeModal: PropTypes.func.isRequired,
  modalOpen: PropTypes.bool.isRequired,
  isAdd: PropTypes.bool.isRequired,
  resetMeetingForEdit: PropTypes.func,
  selectedMeetingForEdit: PropTypes.object,
  time: PropTypes.object.isRequired,
  updateGroups: PropTypes.func.isRequired
};

export default MeetingGroupAddEdit;
