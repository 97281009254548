import React from "react";
import PropTypes from "prop-types";
import Modal from "react-modal";
import Select from "react-select";
import Cookies from "universal-cookie";
import sortBy from "lodash/sortBy";

const modalStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    overflow: "visible"
  }
};

Modal.setAppElement("#container");

class MeetingGroupEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: null,
      host: { id: null, full_name: "(unassigned)" },
      attendees: [],
      conflictedUser: {
        id: null,
        first_name: null,
        last_name: null
      },
      conflicts: null,
      conflictModalOpen: false,
      forceTable: "",
      selectedRole: null
    };
    this.addAttendee = this.addAttendee.bind(this);
    this.addConflictedAttendee = this.addConflictedAttendee.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.forceTable = this.forceTable.bind(this);
    this.openModal = this.openModal.bind(this);
    this.removeAttendee = this.removeAttendee.bind(this);
    this.removeHost = this.removeHost.bind(this);
    this.resetConflict = this.resetConflict.bind(this);
    this.setConflicts = this.setConflicts.bind(this);
    this.setForceTable = this.setForceTable.bind(this);
    this.setTopic = this.setTopic.bind(this);
    this.setSelectedRole = this.setSelectedRole.bind(this);
    this.updateDetails = this.updateDetails.bind(this);
    this.updateHost = this.updateHost.bind(this);
  }

  static getDerivedStateFromProps(newProps, state) {
    if (newProps.selectedMeetingForEdit.id === state.id) {
      return null;
    }
    if (newProps.selectedMeetingForEdit.id !== null) {
      const group = newProps.selectedMeetingForEdit;
      return {
        id: group.id,
        host: group.host || { id: null, full_name: "(unassigned)" },
        attendees: group.attendees || [],
        topic: group.topic || "",
        conflictedUser: {
          id: null,
          first_name: null,
          last_name: null
        },
        conflicts: null,
        forceTable: ""
      };
    }
    return {
      id: null,
      host: { id: null, full_name: "(unassigned)" },
      attendees: [],
      topic: "",
      conflictedUser: {
        id: null,
        first_name: null,
        last_name: null
      },
      conflicts: null,
      forceTable: ""
    };
  }

  setConflicts(conflicts) {
    this.setState({ conflicts });
  }

  setSelectedRole(role) {
    this.setState({ selectedRole: role });
  }

  setForceTable(event) {
    this.setState({
      forceTable: event.target.value
    });
  }

  setTopic(event) {
    this.setState({
      topic: event.target.value
    });
  }

  resetConflict() {
    this.setState({
      conflictedUser: {
        id: null,
        first_name: null,
        last_name: null
      },
      conflicts: [],
      conflictModalOpen: false
    });
  }

  openModal() {
    this.setState({ conflictModalOpen: true });
  }

  closeModal() {
    this.setState({ conflictModalOpen: false });
  }

  updateHost(option) {
    const { attendees } = this.props;
    const id = option.value;
    if (id === 0) {
      return;
    }
    const host = attendees.find(att => att.id === id);
    this.setState({ host });
  }

  addAttendee(option) {
    const {
      attendees,
      selectedMeetingForEdit,
      time,
      updateGroups
    } = this.props;
    const id = option.value;
    if (id === 0) {
      return;
    }
    const attendee = attendees.find(att => att.id === id);
    const cookies = new Cookies();
    const token = cookies.get("X-CSRF-Token");
    const data = { attendee: { id: attendee.id } };

    fetch(
      `/times/${time.id}/execs/${selectedMeetingForEdit.id}/attendance/add`,
      {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": token
        }
      }
    )
      .then(response => response.json())
      .then(json => {
        if (json.error == null) {
          this.setState(prevState => ({
            attendees: prevState.attendees.concat(attendee)
          }));
          this.resetConflict();
          updateGroups(json.group);
        } else {
          if ("conflicts" in json) {
            this.setState({
              conflictedSessions: json.conflicts,
              conflictedUser: json.attendee,
              conflictModalOpen: true
            });
          } else {
            alert(json.error);
          }
          console.log(json);
        }
      });
  }

  addConflictedAttendee(id) {
    const {
      attendees,
      selectedMeetingForEdit,
      time,
      updateGroups
    } = this.props;
    if (id === 0) {
      return;
    }
    const attendee = attendees.find(att => att.id === id);
    const cookies = new Cookies();
    const token = cookies.get("X-CSRF-Token");
    const data = { attendee: { id: attendee.id } };

    fetch(
      `/times/${time.id}/execs/${
        selectedMeetingForEdit.id
      }/attendance/add_conflict`,
      {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": token
        }
      }
    )
      .then(response => response.json())
      .then(json => {
        if (json.error == null) {
          this.setState(prevState => ({
            attendees: prevState.attendees.concat(attendee)
          }));
          this.resetConflict();
          updateGroups(json.group);
          json.updated_groups.forEach(group => {
            updateGroups(group);
          });
        } else {
          console.log(json);
          alert(json.error);
        }
      });
  }

  removeAttendee(id) {
    const {
      attendees,
      selectedMeetingForEdit,
      time,
      updateGroups
    } = this.props;
    if (id === 0) {
      return;
    }
    const attendee = attendees.find(att => att.id === id);
    const cookies = new Cookies();
    const token = cookies.get("X-CSRF-Token");
    const data = { attendee: { id: attendee.id } };

    fetch(
      `/times/${time.id}/execs/${selectedMeetingForEdit.id}/attendance/remove`,
      {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": token
        }
      }
    )
      .then(response => response.json())
      .then(json => {
        if (json.error == null) {
          this.setState(prevState => ({
            attendees: prevState.attendees.filter(ele => ele.id !== attendee.id)
          }));
          this.resetConflict();
          updateGroups(json.group);
        } else {
          alert(json.error);
          console.log(json);
        }
      });
  }

  updateDetails() {
    const { selectedMeetingForEdit, time } = this.props;
    const { topic } = this.state;
    const cookies = new Cookies();
    const token = cookies.get("X-CSRF-Token");
    const data = { meeting_group: {
        topic: topic
      }
    }

    fetch(
      `/times/${time.id}/execs/${selectedMeetingForEdit.id}/set_details`,
      {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": token
        }
      }
    ).then(response => response.json())
      .then(json => {
        if (json.error == null) {
          updateGroups(json.group);
        } else {
          alert(json.error);
          console.log(json);
        }
      });
  }

  removeHost() {
    this.setState({ host: { id: null, full_name: "(unassigned)" } });
  }

  forceTable() {
    const {
      selectedMeetingForEdit,
      time,
      updateGroups
    } = this.props;
    const { forceTable } = this.state;
    const cookies = new Cookies();
    const token = cookies.get("X-CSRF-Token");
    const data = { table: { number: forceTable } };

    fetch(`/times/${time.id}/execs/${selectedMeetingForEdit.id}/force_table`, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": token
      }
    })
      .then(response => response.json())
      .then(json => {
        if (json.error == null) {
          alert("Table forced");
          updateGroups(json.group);
        } else {
          alert("could not force table");
          console.log(json);
        }
      });
  }

  excludedAttendees() {
    // id values of attendees selected as host or attendee already
    const { host, attendees } = this.state;
    let excluded = [];
    if (host) {
      excluded.concat(host.id);
    }
    excluded = excluded.concat(attendees.map(att => att.id));
    return excluded;
  }

  attendeeSelectOptions() {
    const { selectedRole } = this.state;
    const { attendees } = this.props;
    const excludedIds = this.excludedAttendees();
    let attendeeList = sortBy(
      attendees.filter(att => !excludedIds.includes(att.id)),
      ["first_name", "last_name"]
    );

    // if role filter is active, filter out attendees by role
    if (selectedRole && selectedRole.value != 0) {
      attendeeList = attendeeList.filter(att => att.role === selectedRole.label)
    }

    return attendeeList.map(att => ({
      value: att.id,
      label: `${att.full_name} / ${att.company} / (${att.role})`
    }));
  }

  renderAttendeeName(attendee) {
    if (attendee) {
      return attendee.full_name;
    }
    return "(unassigned)";
  }

  renderAttendeeCompany(attendee) {
    if (attendee.id !== null) {
      return attendee.company;
    }
    return "";
  }

  renderAttendeeTitle(attendee) {
    if (attendee.id !== null) {
      return attendee.job_title;
    }
    return "";
  }

  renderAttendeeRole(attendee) {
    if (attendee.id !== null) {
      return attendee.role;
    }
    return "";
  }

  renderAttendeeTable(attendees) {
    const attendeeList = [].concat(attendees);
    return attendeeList.map(att => (
      <tr key={`attendee-${att.id}`}>
        <td className="group-modal-attendee-table-name">
          {this.renderAttendeeName(att)}
        </td>
        <td className="group-modal-attendee-table-company">
          {this.renderAttendeeCompany(att)}
        </td>
        <td className="group-modal-attendee-table-title">
          {this.renderAttendeeTitle(att)}
        </td>
        <td className="group-modal-attendee-table-role">
          {this.renderAttendeeRole(att)}
        </td>
        <td className="group-modal-attendee-table-actions">
          <a
            onClick={() => {
              this.removeAttendee(att.id);
            }}
          >
            remove
          </a>
        </td>
      </tr>
    ));
  }

  renderHost(host) {
    if (host === null || host.id === null) {
      return (
        <tr>
          <td className="group-modal-attendee-table-name">
            (no host assigned)
          </td>
          <td className="group-modal-attendee-table-company">&nbsp;</td>
          <td className="group-modal-attendee-table-title">&nbsp;</td>
          <td className="group-modal-attendee-table-role">&nbsp;</td>
          <td className="group-modal-attendee-table-actions">&nbsp;</td>
        </tr>
      );
    }
    return (
      <tr key={`host-${host.id}`}>
        <td className="group-modal-attendee-table-name">
          {this.renderAttendeeName(host)}
        </td>
        <td className="group-modal-attendee-table-company">
          {this.renderAttendeeCompany(host)}
        </td>
        <td className="group-modal-attendee-table-title">
          {this.renderAttendeeTitle(host)}
        </td>
        <td className="group-modal-attendee-table-role">
          {this.renderAttendeeRole(host)}
        </td>
        <td className="group-modal-attendee-table-actions">
          <a
            onClick={() => {
              this.removeHost();
            }}
          >
            remove
          </a>
        </td>
      </tr>
    );
  }

  renderConflicts() {
    const { conflicts } = this.state;
    if (conflicts) {
      return (
        <div className="row">
          <div className="col-md-12">
            <div className="meeting-group-modal-conflicts">
              {conflicts.map(conflict => this.renderUsersConflict(conflict))}
            </div>
          </div>
        </div>
      );
    }
    return <React.Fragment />;
  }

  nameSort(a, b) {
    const nameA = a.name.toUpperCase();
    const nameB = b.name.toUpperCase();
    if (nameA > nameB) {
      return -1;
    }
    if (nameA < nameB) {
      return 1;
    }
    return 0;
  }

  renderRoleOptions() {
    const { roles } = this.props;
    return roles
      .sort((a, b) => this.nameSort(a, b))
      .concat({ id: 0, name: "Filter by role" })
      .reverse()
      .map(role => ({ value: role.id, label: role.name }));
  }

  renderUsersConflict(conflict) {
    const conflictList = [];
    conflict.conflicts.forEach(conf => {
      if (conf.meeting_group_time_id) {
        conflictList.push(<p>1:1 Meeting</p>);
      } else {
        conflictList.push(<p>{conf.name}</p>);
      }
    });

    return (
      <div key={`conflict-attendee-${conflict.attendee.id}`}>
        Conflicts for {conflict.attendee.first_name}{" "}
        {conflict.attendee.last_name}:<br />
        {conflictList}
      </div>
    );
  }

  renderConflictModal() {
    const { conflictedUser, conflictModalOpen } = this.state;
    return (
      <Modal
        isOpen={conflictModalOpen}
        onRequestClose={this.closeModal}
        style={modalStyles}
        contentLabel="Conflict Detected"
      >
        <h2>Conflict Detected</h2>
        <div>
          <p>
            A conflict has been detected for {conflictedUser.first_name}{" "}
            {conflictedUser.last_name}.
          </p>
          <p>
            Click &quot;Add&quot; to add attendee to this meeting and remove
            them from their previously-scheduled meeting.
          </p>
          <p>&nbsp;</p>
          <button
            className="btn btn-round btn-info"
            type="button"
            onClick={this.resetConflict}
          >
            Cancel
          </button>
          <button
            className="btn btn-round btn-primary"
            type="button"
            onClick={() => this.addConflictedAttendee(conflictedUser.id)}
          >
            Add
          </button>
        </div>
      </Modal>
    );
  }

  renderHostSection(host) {
    return (
      <div className="row">
        <div className="col-md-11">
          <label>Host</label>
          <br />
          <table className="attendee-table">
            <tbody>{this.renderHost(host)}</tbody>
          </table>
          <label>Set Host:</label>
          <br />
          <Select
            value={0}
            onChange={this.updateHost}
            options={this.attendeeSelectOptions()}
            onSelectResetsInput
            placeholder="Select a host"
          />
        </div>
      </div>
    );
  }

  renderForceTable() {
    const { forceTable } = this.state;
    return (
      <div className="row">
        <div className="col-md-11">
          <label>Force table assignment</label>
          <br />
          <input type="text" value={forceTable} onChange={this.setForceTable} />
          &nbsp;
          <button type="button" onClick={this.forceTable}>
            Force It
          </button>
        </div>
      </div>
    );
  }

  renderAttendeesSection(attendees) {
    const { selectedRole } = this.props;
    return (
      <div className="row">
        <div className="col-md-11">
          <label>Attendees</label>
          <br />
          <table className="attendee-table">
            <thead>
              <tr>
                <th>Name</th>
                <th>Company</th>
                <th>Title</th>
                <th>Role</th>
                <th>&nbsp;</th>
              </tr>
            </thead>
            <tbody>{this.renderAttendeeTable(attendees)}</tbody>
          </table>
          <label>Add Attendee:</label>
          <div className="meeting-groups-role-filter">
            <Select
              options={this.renderRoleOptions()}
              value={selectedRole}
              onChange={this.setSelectedRole}
              placeholder="Filter by role"
            />
          </div>
          <br />
          <Select
            value={0}
            onChange={this.addAttendee}
            options={this.attendeeSelectOptions()}
            onSelectResetsInput
            placeholder="Select attendee to add"
          />
        </div>
      </div>
    );
  }

  renderFields() {
    const { topic } = this.state;
    return (
      <div className="row">
        <div className="col-md-2">
          <label>Meeting Topic:</label>
        </div>
        <div className="col-md-10">
          <input type="text" style={{ width: "75%" }} value={topic} onChange={this.setTopic} onBlur={this.updateDetails} />
        </div>
      </div>
    );
  }

  render() {
    const { editModalOpen, closeEditModal, time } = this.props;
    const { attendees } = this.state;
    return (
      <Modal
        isOpen={editModalOpen}
        onRequestClose={closeEditModal}
        style={modalStyles}
        contentLabel="Meeting Group"
      >
        <div className="meeting-group-edit-modal">
          <h2>Meeting Group</h2>
          <div className="row">
            <div className="col-md-6">
              <b>Date:</b> {time.meeting_date}
            </div>
            <div className="col-md-6">
              <b>Time:</b> {time.meeting_time_start_fmt}
            </div>
          </div>
          {this.renderFields()}
          {this.renderConflictModal()}
          {this.renderAttendeesSection(attendees)}
          {this.renderForceTable()}
          <div className="row">
            <div className="col-md-12">
              <div className="pull-right">
                <button
                  className="btn btn-info btn-round"
                  type="button"
                  onClick={closeEditModal}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}
/* eslint-enable camelcase */

MeetingGroupEdit.propTypes = {
  attendees: PropTypes.array.isRequired,
  editModalOpen: PropTypes.bool.isRequired,
  closeEditModal: PropTypes.func.isRequired,
  resetMeetingForEdit: PropTypes.func.isRequired,
  roles: PropTypes.array.isRequired,
  selectedMeetingForEdit: PropTypes.object.isRequired,
  time: PropTypes.object.isRequired,
  updateGroups: PropTypes.func.isRequired
};

export default MeetingGroupEdit;
