import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import Cookies from 'universal-cookie';

const modalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)'
  }
};

Modal.setAppElement('#container');

class BatchPdf extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false,
      downloadUrl: null,
      pollUrl: null
    };
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.generateZipRequest = this.generateZipRequest.bind(this);
    this.fetchZip = this.fetchZip.bind(this);
  }

  openModal() {
    this.setState({ modalOpen: true });
    this.generateZipRequest();
  }

  closeModal() {
    this.setState({ modalOpen: false });
  }

  generateZipRequest() {
    const { url } = this.props;
    const cookies = new Cookies();
    const token = cookies.get("X-CSRF-Token");

    fetch(url, {
      method: "GET",
      redirect: "manual",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": token
      }
    })
      .then(response => {
        return response.json();
      })
      .then(json => {
        if (json.error == null) {
          const pollUrl = json.fetch_path;
          this.setState({
            pollUrl: pollUrl
          }, () => {
            setTimeout(this.fetchZip, 10000)
          });
        } else {
          console.log(json.error);
        }
      });
  }

  fetchZip() {
    const { pollUrl } = this.state;
    const cookies = new Cookies();
    const token = cookies.get("X-CSRF-Token");

    fetch(pollUrl, {
      method: "GET",
      redirect: "manual",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": token
      }
    })
      .then(response => {
        return response.json();
      })
      .then(json => {
        if (json.error != null) {
          console.log(json.error);
        } else {
          if (json.complete) {
            this.setState({
              downloadUrl: json.url
            })
          } else {
            setTimeout(this.fetchZip, 10000);
          }
        }
      });
  }

  renderModal() {
    const { downloadUrl } = this.state;

    if (downloadUrl) {
      return (
        <>
          <a className="sg-link" href={downloadUrl} target="_blank">Click here to download PDFs</a>

        </>
      );
    }
    return (
      <>
        <p>Generating the requested schedules</p>
        <p>Please wait...</p>
      </>
    );
  }

  render() {
    const { modalOpen } = this.state;
    return (
      <>
        <span className="sg-link" onClick={this.openModal}>Download</span>
        <Modal
          isOpen={modalOpen}
          onRequestClose={this.closeModal}
          style={modalStyles}
          contentLabel="pdfs"
        >
          <div className="venue-add-edit-modal">
            <h2>Attendee Schedule PDFs</h2>
            {this.renderModal()}
          </div>
        </Modal>
      </>
    );
  }
}

BatchPdf.propTypes = {
  url: PropTypes.string.isRequired
};

export default BatchPdf;
