import React from "react";
import PropTypes from "prop-types";
import sortBy from "lodash/sortBy";
import lowerCase from "lodash/lowerCase";
import Select from "react-select";
import SessionAttendeesAttendee from "./SessionAttendeesAttendee";

class SessionAttendeesList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visibleAttendees: props.attendees,
      searchFragment: "",
      selectedRole: ""
    };
    this.setSearchValue = this.setSearchValue.bind(this);
    this.setSelectedRole = this.setSelectedRole.bind(this);
    this.setVisibleAttendees = this.setVisibleAttendees.bind(this);
    this.filterVisibleAttendees = this.filterVisibleAttendees.bind(this);
  }

  static getDerivedStateFromProps(newProps, state) {
    const searchLower = lowerCase(state.searchFragment);
    const { selectedRole } = state;
    let attendeeList = sortBy(newProps.attendees, [
      "last_name",
      "first_name"
    ]).filter(
      attendee =>
        lowerCase(attendee.first_name).includes(searchLower) ||
        lowerCase(attendee.last_name).includes(searchLower) ||
        lowerCase(attendee.job_title).includes(searchLower) ||
        lowerCase(attendee.company).includes(searchLower)
    );
    if (selectedRole && selectedRole.value !== 0) {
      attendeeList = attendeeList.filter(
        attendee => attendee.attendee_role_id === selectedRole.value
      );
    }
    return {
      visibleAttendees: attendeeList
    };
  }

  setSearchValue(event) {
    this.setState({ searchFragment: event.target.value }, () => {
      const { attendees } = this.props;
      const visibleAttendees = this.filterVisibleAttendees(attendees);
      this.setVisibleAttendees(visibleAttendees);
    });
  }

  setVisibleAttendees(attendees) {
    this.setState({ visibleAttendees: attendees });
  }

  setSelectedRole(selected) {
    this.setState({ selectedRole: selected });
    const { attendees } = this.props;
    const visibleAttendees = this.filterVisibleAttendees(attendees);
    this.setVisibleAttendees(visibleAttendees);
  }

  filterVisibleAttendees(attendees) {
    const { searchFragment, selectedRole } = this.state;
    const searchLower = lowerCase(searchFragment);
    let attendeeList = sortBy(attendees, ["last_name", "first_name"]).filter(
      attendee =>
        lowerCase(attendee.first_name).includes(searchLower) ||
        lowerCase(attendee.last_name).includes(searchLower) ||
        lowerCase(attendee.job_title).includes(searchLower) ||
        lowerCase(attendee.company).includes(searchLower)
    );
    if (selectedRole) {
      attendeeList = attendeeList.filter(
        attendee => attendee.attendee_role_id === selectedRole.value
      );
    }
    return attendeeList;
  }

  nameSort(a, b) {
    const nameA = a.name.toUpperCase();
    const nameB = b.name.toUpperCase();
    if (nameA > nameB) {
      return -1;
    }
    if (nameA < nameB) {
      return 1;
    }
    return 0;
  }

  renderAttendees() {
    const { assigned, changeFn, roles } = this.props;
    const { visibleAttendees } = this.state;
    const attendeeList = visibleAttendees.map(attendee => (
      <SessionAttendeesAttendee
        attendee={attendee}
        assigned={assigned}
        changeFn={changeFn}
        key={`attendee-${attendee.id}`}
        roles={roles}
      />
    ));
    return attendeeList;
  }

  renderTableHeader() {
    return (
      <tr>
        <th className="session-attendee-first-name">First Name</th>
        <th className="session-attendee-last-name">Last Name</th>
        <th className="session-attendee-company">Company</th>
        <th className="session-attendee-role">Role</th>
        <th className="session-attendee-controls">&nbsp;</th>
      </tr>
    );
  }

  renderRoleOptions() {
    const { roles } = this.props;
    return roles
      .sort((a, b) => this.nameSort(a, b))
      .concat({ id: 0, name: "Select role" })
      .reverse()
      .map(role => ({ value: role.id, label: role.name }));
  }

  renderHeader() {
    const { attendees, assigned } = this.props;
    if (assigned) {
      return `Assigned to this Session (${attendees.length})`;
    }
    return `Available Attendees (${attendees.length})`;
  }

  renderAssignAll() {
    const { allFn } = this.props;
    const { selectedRole, visibleAttendees } = this.state;
    let label = "";
    if (!allFn) {
      return <React.Fragment />;
    }
    if (selectedRole && selectedRole.value != 0) {
      label = ` ${selectedRole.label}`
    }
    return (
      <div className="session-attendees-assign-all">
        <span
          className="session-attendee-add"
          role="button"
          onClick={() => allFn(visibleAttendees.map(ele => ele.id))}
        >
          {`Assign All${label}`}
        </span>
      </div>
    );
  }

  renderControls() {
    const { searchFragment, selectedRole, visibleAttendees } = this.state;
    const { attendees } = this.props;
    return (
      <React.Fragment>
        <div className="session-attendees-search-count">
          Showing {visibleAttendees.length} of {attendees.length}
        </div>
        <div className="session-attendees-controls">
          {this.renderAssignAll()}
          <div className="session-attendees-select-container">
            <Select
              options={this.renderRoleOptions()}
              value={selectedRole}
              onChange={this.setSelectedRole}
              placeholder="Select role"
            />
          </div>
          <i
            className="fa fa-search session-attendees-search-icon"
            aria-hidden="true"
          />
          <input
            className="session-attendees-search-box"
            value={searchFragment}
            onChange={this.setSearchValue}
          />
        </div>
      </React.Fragment>
    );
  }

  render() {
    return (
      <div className="session-attendees-unassigned">
        <div className="session-attendees-header">{this.renderHeader()}</div>
        <div className="session-attendees-controls-container">
          {this.renderControls()}
        </div>
        <div className="session-attendees-list-header">
          <table className="session-attendees-list-table">
            <thead>{this.renderTableHeader()}</thead>
            <tbody />
          </table>
        </div>
        <div className="session-attendees-list">
          <table className="session-attendees-list-table">
            <tbody>{this.renderAttendees()}</tbody>
          </table>
        </div>
      </div>
    );
  }
}

SessionAttendeesList.defaultProps = {
  allFn: null
};

SessionAttendeesList.propTypes = {
  allFn: PropTypes.func,
  attendees: PropTypes.array.isRequired,
  assigned: PropTypes.bool.isRequired,
  changeFn: PropTypes.func.isRequired,
  roles: PropTypes.array.isRequired
};

export default SessionAttendeesList;
