import React from 'react';
import PropTypes from 'prop-types';
import Cookies from 'universal-cookie';
import MeetingGroupTimeList from './MeetingGroupTimeList';
import MeetingGroups from './MeetingGroups';

class MeetingGroupTime extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      groups: props.groups,
      times: props.times,
      time: props.time
    };
    this.assignTables = this.assignTables.bind(this);
  }

  assignTables() {
    const cookies = new Cookies();
    const token = cookies.get("X-CSRF-Token");
    const data = {};

    fetch(`/times/assign`, {
      method: 'post',
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        'X-CSRF-Token': token
      },
    }).then(response => response.json())
      .then((json) => {
        if (json.error == null) {
          alert("Table assignment performed. Reloading page...");
          location.reload();
        } else {
          alert(json.error);
          console.log(json);
        }
      });
  }

  // TODO: compare timestamp with assignments to detect if stale
  // probably have to do on back-end
  renderTimestamp() {
    const { timestamp } = this.props;
    if (timestamp) {
      return (
        <div className="pull-left">
          <em>
            Table assignment last performed {timestamp.stamp} (result: {timestamp.result})
          </em>
          <br />
        </div>
      );
    }
    return <React.Fragment />;
  }

  renderTimeName() {
    const { time } = this.props;
    return (
      <div><h3 style={{ "margin": "0"}}>{time.name}</h3></div>
    );
  }

  render() {
    const { groups, time, times } = this.state;
    const { attendees, roles } = this.props;
    return (
      <div className="venue-container">
        <h2>Exec 1:1 Meetings</h2>
        <div className="row">
          <div className="col-md-3">
            <MeetingGroupTimeList times={times} time={time} />
          </div>
          <div className="col-md-9">
            {/* {this.renderTimestamp()} */}
            <a href={`/times/${time.id}/edit`} className="btn btn-primary btn-round pull-right">Edit Date/Time</a>
            {/* <button type="button" className="btn btn-round btn-primary pull-right" onClick={this.assignTables}>Assign Tables</button> */}
            <br />
            {this.renderTimeName()}
            <MeetingGroups attendees={attendees} groups={groups} time={time} roles={roles} />
          </div>
        </div>
      </div>
    );
  }
}

MeetingGroupTime.defaultProps = {
  timestamp: null
};

MeetingGroupTime.propTypes = {
  attendees: PropTypes.array.isRequired,
  groups: PropTypes.array.isRequired,
  roles: PropTypes.array.isRequired,
  time: PropTypes.object.isRequired,
  times: PropTypes.array.isRequired,
  timestamp: PropTypes.object
};

export default MeetingGroupTime;
