import React from "react";
import PropTypes from "prop-types";
import sortBy from "lodash/sortBy";

class VenueList extends React.Component {
  renderVenues() {
    const { venues } = this.props;
    return sortBy(venues, ["id", "name"]).map(venue => (
      <tr key={venue.id}>
        <td className="venue-list">
          <a href={`/venues/${venue.id}`}>
            <div className="select-flag padding">{venue.name}</div>
          </a>
        </td>
      </tr>
    ));
  }

  render() {
    return (
      <table className="table table-bordered table-striped settings venue-list-table">
        <tbody>
          {this.renderVenues()}
          <tr>
            <td className="venue-list">
              <a href="/venues/new">
                <div className="select-flag padding">Add New Venue</div>
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    );
  }
}

VenueList.propTypes = {
  venues: PropTypes.array.isRequired
};

export default VenueList;
