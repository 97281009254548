import React from "react";
import PropTypes from "prop-types";

class SessionAttendeesAttendee extends React.Component {
  renderControl() {
    const { attendee, assigned, changeFn } = this.props;
    if (assigned) {
      return (
        <span
          className="session-attendee-remove"
          role="button"
          onClick={() => changeFn(attendee.id)}
        >
          Remove
        </span>
      );
    }
    return (
      <span
        className="session-attendee-add"
        role="button"
        onClick={() => changeFn(attendee.id)}
      >
        Add
      </span>
    );
  }

  renderRole(roleId) {
    const { roles } = this.props;
    const attendeeRole = roles.find(role => role.id === roleId);
    return attendeeRole.name;
  }

  render() {
    const { attendee } = this.props;
    return (
      <tr className="session-attendee">
        <td className="session-attendee-first-name">{attendee.first_name}</td>
        <td className="session-attendee-last-name">{attendee.last_name}</td>
        <td className="session-attendee-company">{attendee.company}</td>
        <td className="session-attendee-role">
          {this.renderRole(attendee.attendee_role_id)}
        </td>
        <td className="session-attendee-controls">{this.renderControl()}</td>
      </tr>
    );
  }
}

SessionAttendeesAttendee.propTypes = {
  attendee: PropTypes.object.isRequired,
  assigned: PropTypes.bool.isRequired,
  changeFn: PropTypes.func.isRequired,
  roles: PropTypes.array.isRequired
};

export default SessionAttendeesAttendee;
