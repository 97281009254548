import React from "react";
import PropTypes from "prop-types";
import Modal from "react-modal";
import Cookies from "universal-cookie";
import SessionAttendeesList from "./SessionAttendeesList";

const modalStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)"
  }
};

Modal.setAppElement("#container");

class SessionAssignment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      assigned: props.assigned,
      unassigned: props.unassigned,
      conflictedUser: {
        id: null,
        first_name: null,
        last_name: null
      },
      conflictedSessions: [],
      conflictModalOpen: false
    };
    this.addAssignment = this.addAssignment.bind(this);
    this.addGroupAssignment = this.addGroupAssignment.bind(this);
    this.removeAssignment = this.removeAssignment.bind(this);
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.resetConflict = this.resetConflict.bind(this);
  }

  openModal() {
    this.setState({ conflictModalOpen: true });
  }

  closeModal() {
    this.setState({ conflictModalOpen: false });
  }

  resetConflict() {
    this.setState({
      conflictedUser: {
        id: null,
        first_name: null,
        last_name: null
      },
      conflictedSessions: [],
      conflictModalOpen: false
    });
  }

  conflictedSessionName(sessions) {
    if (!sessions) {
      return <React.Fragment />;
    }

    const output = [];

    sessions.forEach(session => {
      if (session.name) {
        output.push(
          <div
            className="session-assignment-conflict"
            key={`conflict-id-${session.id}`}
          >
            {session.name}
          </div>
        );
      } else {
        output.push(
          <div
            className="session-assignment-conflict"
            key={`conflict-id-${session.id}`}
          >
            An Exec 1:1 meeting
          </div>
        );
      }
    });

    if (output) {
      return (
        <div className="session-assignment-conflicts">
          This attendee has the following conflicts at this time:
          <br />
          {output}
        </div>
      );
    }
    return <React.Fragment />;
  }

  addAssignment(attendeeId, conflict = false) {
    const { session } = this.props;
    const cookies = new Cookies();
    const token = cookies.get("X-CSRF-Token");
    const data = { attendee: { id: attendeeId } };
    let endpoint = "add";
    if (conflict) {
      endpoint = `${conflict}_conflict`;
    }

    fetch(`/sessions/${session.id}/attendance/${endpoint}`, {
      method: "post",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": token
      }
    })
      .then(response => response.json())
      .then(json => {
        if (json.error == null) {
          this.setState(prevState => ({
            unassigned: prevState.unassigned.filter(
              ele => ele.id !== json.attendee.id
            ),
            assigned: prevState.assigned.concat(json.attendee)
          }));
          this.resetConflict();
        } else {
          if ("conflicts" in json) {
            this.setState({
              conflictedSessions: json.conflicts,
              conflictedUser: json.attendee,
              conflictModalOpen: true
            });
          } else {
            alert(json.error);
          }
          console.log(json);
        }
      });
  }

  addGroupAssignment(attendeeIds) {
    const { session } = this.props;
    const { unassigned } = this.state;
    const cookies = new Cookies();
    const token = cookies.get("X-CSRF-Token");
    const data = { attendeeIds: attendeeIds };

    fetch(`/sessions/${session.id}/attendance/add_group`, {
      method: "post",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": token
      }
    })
      .then(response => response.json())
      .then(json => {
        if (json.error == null) {
          const newlyAssigned = unassigned.filter(att => json.attendeeIds.includes(att.id));
          this.setState(prevState => ({
            unassigned: prevState.unassigned.filter(
              ele => !json.attendeeIds.includes(ele.id)
            ),
            assigned: prevState.assigned.concat(newlyAssigned)
          }));
          this.resetConflict();
        } else {
          if ("conflicts" in json) {
            this.setState({
              conflictedSessions: json.conflicts,
              conflictedUser: json.attendee,
              conflictModalOpen: true
            });
          } else {
            alert(json.error);
          }
          console.log(json);
        }
      });
  }

  removeAssignment(attendeeId) {
    const { session } = this.props;
    const cookies = new Cookies();
    const token = cookies.get("X-CSRF-Token");
    const data = { attendee: { id: attendeeId } };
    fetch(`/sessions/${session.id}/attendance/remove`, {
      method: "post",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": token
      }
    })
      .then(response => response.json())
      .then(json => {
        if (json.error == null) {
          this.setState(prevState => ({
            assigned: prevState.assigned.filter(
              ele => ele.id !== json.attendee.id
            ),
            unassigned: prevState.unassigned.concat(json.attendee)
          }));
        } else {
          alert(json.error);
        }
      });
  }

  render() {
    const {
      assigned,
      unassigned,
      conflictedUser,
      conflictedSessions,
      conflictModalOpen
    } = this.state;
    const { roles } = this.props;
    return (
      <div className="session-attendees-container">
        <SessionAttendeesList
          attendees={assigned}
          assigned
          changeFn={this.removeAssignment}
          roles={roles}
        />
        <div className="session-attendees-list-separator" />
        <SessionAttendeesList
          attendees={unassigned}
          assigned={false}
          changeFn={this.addAssignment}
          allFn={this.addGroupAssignment}
          roles={roles}
        />
        <Modal
          isOpen={conflictModalOpen}
          onRequestClose={this.closeModal}
          style={modalStyles}
          contentLabel="Conflict Detected"
        >
          <h2>Conflict Detected</h2>
          <div>
            <p>A conflict has been detected.</p>
            {this.conflictedSessionName(conflictedSessions)}
            <p>
              Click &quot;Add and Remove Conflict&quot; to add attendee to this
              session and remove them from their previously-scheduled
              session(s).
            </p>
            <p>
              Click &quot;Add and Keep Conflict&quot; to add attendee to this
              session and allow them to still be assigned to the conflicting
              session(s).
            </p>
            <p>&nbsp;</p>
            <button
              className="btn btn-round btn-info"
              type="button"
              onClick={this.resetConflict}
            >
              Cancel
            </button>
            <button
              className="btn btn-round btn-primary"
              type="button"
              onClick={() => this.addAssignment(conflictedUser.id, "replace")}
            >
              Add and Remove Conflict(s)
            </button>
            <button
              className="btn btn-round btn-primary"
              type="button"
              onClick={() => this.addAssignment(conflictedUser.id, "add")}
            >
              Add and Keep Conflict(s)
            </button>
          </div>
        </Modal>
      </div>
    );
  }
}

SessionAssignment.defaultProps = {
  assigned: [],
  unassigned: []
};

SessionAssignment.propTypes = {
  assigned: PropTypes.array,
  unassigned: PropTypes.array,
  roles: PropTypes.array.isRequired,
  session: PropTypes.object.isRequired
};

export default SessionAssignment;
