import React from "react";
import PropTypes from "prop-types";
import Cookies from "universal-cookie";
import lowerCase from "lodash/lowerCase";
import sortBy from "lodash/sortBy";
import MeetingGroupEdit from "./MeetingGroupEdit";

const emptyEditMeeting = {
  id: null,
  host: { id: null, full_name: "(unassigned)" },
  attendees: []
};

class MeetingGroups extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      addModalOpen: false,
      editModalOpen: false,
      groups: props.groups,
      searchFragment: "",
      selectedMeetingForEdit: emptyEditMeeting
    };
    this.addGroup = this.addGroup.bind(this);
    this.closeAddModal = this.closeAddModal.bind(this);
    this.closeEditModal = this.closeEditModal.bind(this);
    this.openAddModal = this.openAddModal.bind(this);
    this.openEditModal = this.openEditModal.bind(this);
    this.resetMeetingForEdit = this.resetMeetingForEdit.bind(this);
    this.removeGroup = this.removeGroup.bind(this);
    this.selectMeetingForEdit = this.selectMeetingForEdit.bind(this);
    this.setSearchValue = this.setSearchValue.bind(this);
    this.updateGroups = this.updateGroups.bind(this);
  }

  setSearchValue(event) {
    this.setState({ searchFragment: event.target.value });
  }

  openAddModal() {
    this.setState({ addModalOpen: true });
  }

  closeAddModal() {
    this.setState({ addModalOpen: false });
  }

  openEditModal() {
    this.setState({ editModalOpen: true });
  }

  closeEditModal() {
    this.setState({ editModalOpen: false });
  }

  resetMeetingForEdit() {
    this.setState({ selectedMeetingForEdit: emptyEditMeeting });
  }

  removeGroup(id) {
    this.setState(prevState => ({
      groups: prevState.groups.filter(ele => ele.id !== id)
    }));
  }

  selectMeetingForEdit(id) {
    const { groups } = this.state;
    const groupToEdit = groups.filter(group => group.id === id)[0];
    this.setState({ selectedMeetingForEdit: groupToEdit });
    this.openEditModal();
  }

  updateGroups(group) {
    const { groups } = this.state;
    const newGroups = groups.filter(ele => ele.id !== group.id).concat(group);
    this.setState({ groups: newGroups });
  }

  filterVisibleGroups(groups) {
    const { searchFragment } = this.state;
    const searchLower = lowerCase(searchFragment);
    let groupList = [];
    try {
      groupList = groups.filter(
        group =>
          lowerCase(group.host.full_name).includes(searchLower) ||
          lowerCase(group.attendees.map(att => att.full_name).join()).includes(
            searchLower
          )
      );
    } catch {
      groupList = groups;
    }
    return groupList;
  }

  /* eslint-disable camelcase */
  deleteGroup(id) {
    const { time } = this.props;
    const cookies = new Cookies();
    const token = cookies.get("X-CSRF-Token");
    const data = {};

    fetch(`/times/${time.id}/execs/${id}`, {
      method: "delete",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": token
      }
    })
      .then(response => response.json())
      .then(json => {
        if (json.error == null) {
          this.removeGroup(id);
        } else {
          alert(json.error);
          console.log(json);
        }
      });
  }

  /* eslint-disable camelcase */
  addGroup() {
    const { time } = this.props;
    const cookies = new Cookies();
    const token = cookies.get("X-CSRF-Token");
    const data = {};

    fetch(`/times/${time.id}/execs`, {
      method: "post",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": token
      }
    })
      .then(response => response.json())
      .then(json => {
        if (json.error == null) {
          this.updateGroups(json.group);
        } else {
          alert(json.error);
          console.log(json);
        }
      });
  }

  renderHostName(host) {
    if (host) {
      return host.full_name;
    }
    return "(unassigned)";
  }

  renderAttendeeNames(attendees = []) {
    if (attendees.length > 0) {
      return attendees.map(att => att.full_name).join(", ");
    }
    return "";
  }

  renderLocationName(group) {
    if (group.assigned && group.meeting_group_table) {
      return group.meeting_group_table.name_with_room;
    }
    return "";
  }

  renderTopic(group) {
    if (group.topic && group.topic.length > 0) {
      return group.topic;
    }
    return "";
  }

  renderGroups() {
    const { groups } = this.state;
    return sortBy(this.filterVisibleGroups(groups), ["id"]).map(
      (group, idx) => (
        <div className="meeting-group" key={`group-${group.id}`}>
          <div className="meeting-group-number-container">
            <div className="meeting-group-number">{idx + 1}</div>
          </div>
          <div className="meeting-group-details">
            Topic: {this.renderTopic(group)}<br />
            Attendees: {this.renderAttendeeNames(group.attendees.filter(att => att.role !== "Staff"))}<br />
            Staff: {this.renderAttendeeNames(group.attendees.filter(att => att.role === "Staff"))}<br />
            Location: {this.renderLocationName(group)}<br />
          </div>
          <div className="meeting-group-controls">
            <a
              type="button"
              onClick={() => {
                this.selectMeetingForEdit(group.id);
              }}
            >
              Edit
            </a>
            <br />
            <a
              type="button"
              onClick={() => {
                this.deleteGroup(group.id);
              }}
            >
              Delete
            </a>
            <br />
          </div>
        </div>
      )
    );
  }

  renderHeader() {
    const { searchFragment, groups } = this.state;
    return (
      <div className="meeting-groups-header">
        <div className="meeting-groups-count">
          Meeting Groups ({groups.length})
        </div>
        <div className="meeting-groups-controls">
          <i
            className="fa fa-search meeting-groups-search-icon"
            aria-hidden="true"
          />
          <input
            className="meeting-groups-search-box"
            type="text"
            value={searchFragment}
            onChange={this.setSearchValue}
          />
        </div>
      </div>
    );
  }

  render() {
    const { addModalOpen, editModalOpen, selectedMeetingForEdit } = this.state;
    return (
      <div className="meeting-groups-container">
        <p>
          <b>Room/Table Assignments</b>
        </p>
        <ol>
          <li>Create a group and assign attendees.</li>
          <li>
            <strike>The Cisco Scheduling Tool will automatically generate room and
            table assignments based upon group size.</strike>
          </li>
          <li>
            Meetings cannot conflict with existing custom sessions and 1:1
            meetings.
          </li>
        </ol>
        <div className="meeting-groups-list">
          {this.renderHeader()}
          {this.renderGroups()}
          <a type="button" onClick={this.addGroup}>
            + Add a Group
          </a>
          <br />
        </div>
        <MeetingGroupEdit
          {...this.props}
          closeEditModal={this.closeEditModal}
          editModalOpen={editModalOpen}
          resetMeetingForEdit={this.resetMeetingForEdit}
          selectedMeetingForEdit={selectedMeetingForEdit}
          updateGroups={this.updateGroups}
        />
      </div>
    );
  }
}

MeetingGroups.propTypes = {
  attendees: PropTypes.array.isRequired,
  groups: PropTypes.array.isRequired,
  roles: PropTypes.array.isRequired,
  time: PropTypes.object.isRequired
};

export default MeetingGroups;
