import React from "react";
import PropTypes from "prop-types";
import Cookies from "universal-cookie";
import sortBy from "lodash/sortBy";
import { confirmAlert } from "react-confirm-alert";
import VenueRoomAddEdit from "./VenueRoomAddEdit";

import "react-confirm-alert/src/react-confirm-alert.css";

const emptyEditRoom = {
  id: null,
  name: "",
  capacity: 0,
  meeting_groups: false,
  meeting_groups_tables_small: 0,
  meeting_groups_tables_large: 0
};

class VenueRooms extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRoomForEdit: emptyEditRoom
    };
    this.selectRoomForEdit = this.selectRoomForEdit.bind(this);
    this.resetRoomForEdit = this.resetRoomForEdit.bind(this);
    this.deleteRoomConfirm = this.deleteRoomConfirm.bind(this);
    this.deleteRoom = this.deleteRoom.bind(this);
  }

  selectRoomForEdit(id) {
    const { openEditModal, rooms } = this.props;
    const roomToEdit = rooms.filter(room => room.id === id)[0];
    this.setState({ selectedRoomForEdit: roomToEdit });
    openEditModal();
  }

  resetRoomForEdit() {
    this.setState({ selectedRoomForEdit: emptyEditRoom });
  }

  deleteRoomConfirm(id) {
    confirmAlert({
      title: "Confirm removal",
      message: "Are you sure you want to delete this room? This is irreversible. You accept responsibility if you delete the wrong thing.",
      buttons: [
        {
          label: "Yes, delete",
          onClick: () => this.deleteRoom(id)
        },
        {
          label: "No, cancel",
          onClick: () => {}
        }
      ]
    });
  }

  deleteRoom(id) {
    const { venue } = this.props;
    const cookies = new Cookies();
    const token = cookies.get("X-CSRF-Token");

    fetch(`/venues/${venue.id}/rooms/${id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": token
      }
    })
      .then(response => response.json())
      .then(json => {
        if (json.error == null) {
          alert("Room deleted. Reloading room list...");
          window.location.reload();
        } else {
          alert(json.error);
          console.log(json);
        }
      });
  }

  renderRooms() {
    const { rooms } = this.props;
    return sortBy(rooms, ["name"]).map(room => (
      <tr className="venue-rooms-room-row" key={`room-${room.id}`}>
        <td>{room.name}</td>
        <td>{room.capacity}</td>
        <td>{this.renderTableCapacity(room)}</td>
        {/* <td>{this.renderPriority(room)}</td> */}
        <td>
          <a
            type="button"
            onClick={() => {
              this.selectRoomForEdit(room.id);
            }}
          >
            Edit
          </a>
          &nbsp;|&nbsp;
          <a
            type="button"
            onClick={() => {
              this.deleteRoomConfirm(room.id);
            }}
          >
            Delete
          </a>
        </td>
      </tr>
    ));
  }

  renderTableCapacity(room) {
    if (room.meeting_groups) {
      return `${room.meeting_groups_tables_small} / ${
        room.meeting_groups_tables_large
      }`;
    }
    return "--";
  }

  renderPriority(room) {
    return room.priority === 0 ? "--" : room.priority;
  }

  render() {
    const { selectedRoomForEdit } = this.state;
    const {
      addRoomModalOpen,
      closeAddModal,
      closeEditModal,
      editRoomModalOpen
    } = this.props;
    return (
      <div className="venue-rooms">
        <table className="venue-rooms-table">
          <thead>
            <tr>
              <th>Room Name</th>
              <th>Capacity</th>
              <th>1:1 Tables Sm/Lg</th>
              {/* <th>1:1 Priority</th> */}
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>{this.renderRooms()}</tbody>
        </table>
        <VenueRoomAddEdit
          {...this.props}
          closeModal={closeAddModal}
          modalOpen={addRoomModalOpen}
        />
        <VenueRoomAddEdit
          {...this.props}
          closeModal={closeEditModal}
          modalOpen={editRoomModalOpen}
          resetRoomForEdit={this.resetRoomForEdit}
          selectedRoomForEdit={selectedRoomForEdit}
        />
      </div>
    );
  }
}

VenueRooms.propTypes = {
  addRoomModalOpen: PropTypes.bool.isRequired,
  closeAddModal: PropTypes.func.isRequired,
  closeEditModal: PropTypes.func.isRequired,
  editRoomModalOpen: PropTypes.bool.isRequired,
  openEditModal: PropTypes.func.isRequired,
  rooms: PropTypes.array.isRequired,
  updateRooms: PropTypes.func.isRequired,
  venue: PropTypes.object.isRequired
};

export default VenueRooms;
